import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { ActionCall } from './CTA';

function ShowMore() {
    const { t } = useTranslation();

    // const [isOpen, setIsOpen] = useState(true);

    // const toggle = () => setIsOpen(!isOpen);

    return (
        <React.StrictMode>
            <Collapse isOpen={true}>
                <p className='font-22'>{t('about.intro-para-3')} </p>
                <p className='font-22'>{t('about.intro-para-4')}</p>
                <br />
                <ActionCall />
                <br />
                <p className='font-22'>{t('about.regards')} </p>
                <p className='alert-link font-22'>{t('about.thanks')} </p>
            </Collapse>
            {/* <Button color="light" className='w-100' onClick={toggle}>
                {isOpen ? t('about.show-less') : t('about.show-more')}
            </Button> */}
        </React.StrictMode>
    );
}

export default ShowMore;