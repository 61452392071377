import React, { useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const LanguageSwitch: React.FC = () => {

    const { i18n } = useTranslation()
    console.debug('i18n default language ', i18n.language)
    const [language, setLanguage] = useState(i18n.language)

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLanguage(lng)
    }

    const activeButton = (lng: string) => {
        return lng === language ? 'active' : ''
    }

    return (
        <div className='d-flex justify-content-end align-items-center mx-1, me-5' style={{ marginTop: 80}}>
            <ButtonGroup
                className="mt-1"
                aria-label="Basic outlined example"
                size="sm"
            >
                <Button className={activeButton('kn')} outline onClick={() => changeLanguage('kn')}>
                    ಕನ್ನಡ
                </Button>
                <Button className={activeButton('en')} outline onClick={() => changeLanguage('en')}>
                    English
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default LanguageSwitch