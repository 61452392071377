import React from 'react'
import './introduction.css'
import { Container, Row, Col, CardText, CardTitle } from 'reactstrap'
import ShowMore from './ShowMore'
import { useTranslation } from 'react-i18next'
import CTA from './CTA'
import AutoPlayVideoPlayer from './AutoVideoPlayer'

const Introduction: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row
        md="2"
        sm="2"
        xs="1"
      >
        <Col className="border-0 p-md-3 p-lg-5">
          <Row>
            <div className='intro-container'>
              <CardTitle tag="h5">
                {/* {t('about.title')} */}
              </CardTitle>
              <CardText>
                <p className='font-22'>{t('about.intro-para-1')} </p>
                <p className='font-22'>{t('about.intro-para-2')} </p>
                <br />
                <ShowMore />
              </CardText>
            </div>
          </Row>
        </Col>
        <Col className='p-md-3 p-lg-5'>
          {/* <VideoIntro title={t('about.video-title')} /> */}
          <AutoPlayVideoPlayer />
        </Col>
      </Row>
      {/* Another Row of CTA */}
      <CTA />
    </Container>
  )
}

export default Introduction