import React, { useEffect } from 'react';

const TwitterEmbeddedTimeline = () => {

    const width = window.innerWidth < 400 ? window.innerWidth : 400 ;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='d-flex flex-column align-items-center'>
            <a
                className="twitter-timeline"
                href="https://twitter.com/NPRforMLC"
                data-width={`${width - 32}`}
                data-height="500"
            >
                Tweets by NPRforMLC
            </a>
        </div>
    );
}

const Twitter = () => {
    return (
        <div className="d-flex flex-column align-items-center m-2">
            <h4 className="py-3">Twitter updates</h4>
            <TwitterEmbeddedTimeline />
        </div>
    )
}

export default Twitter