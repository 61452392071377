import { Row, Col } from 'reactstrap'
import FacebookPlugin from './Facebook';
import Youtube from './Youtube';
import Twitter from './Twitter';
import Instagram from './Instagram';

const SocialMedia = () => {

    return (
        <>
            <Row>
            </Row>
            <Row
                lg={"4"}
                md="2"
                sm="2"
                xs="1">
                <Col className='p-md-5'>
                    <FacebookPlugin />
                </Col>
                <Col className='p-md-5'>
                    <Twitter />
                </Col>
                <Col className='p-md-5'>
                    <Youtube />
                </Col>
                <Col className='p-md-5'>
                    <Instagram />
                </Col>
            </Row>
        </>
    )
}

export default SocialMedia