import React from 'react'
import { Card } from 'reactstrap'
import Cover from './images/cover.jpg'
import './App.css'

const CoverPhoto: React.FC = () => {
    return (
        <div className='image-container'>
            <Card>
                {/* <CardImg
                    alt="Card image cap"
                    src={Cover}
                /> */}
                <img className='cover-img' alt="Cover page"src={Cover}></img>
                {/* <CardImgOverlay>
                    <CardTitle tag="h5">
                        Card Title
                    </CardTitle>
                    <CardText>
                        This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                    </CardText>
                    <CardText>
                        <small className="text-muted">
                            Last updated 3 mins ago
                        </small>
                    </CardText>
                </CardImgOverlay> */}
            </Card>
        </div>
    )
}

export default CoverPhoto