import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import AppNav from './AppNav';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './translations/translations.en.json';
import kn from './translations/translations.kn.json';
import LanguageSwitch from './LanguageSwitch';
import CoverPhoto from './CoverPhoto';
import './App.css'
import Introduction from './introduction/Introduction';
import Caption from './Caption';
import MediaSection from './Media/MediaSection';
import SocialMedia from './social/SocialMedia';
import Footer from './Footer';
import { useEffect } from 'react';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      kn: {
        translation: kn
      }
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "kn",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const App = () => {

  useEffect(() => {
    ReactGA.initialize('G-GV71DTD3ZV');
    ReactGA.pageview(window.location.pathname + window.location.hash);
  }, [])

  return <>
    <div className='app-X'>
      <AppNav />
      <LanguageSwitch />
      <Caption href='about' title={'cover.title'} >
        <CoverPhoto />
      </Caption>
      <br />
      <Caption href='intro' title={'about.title'}>
        <Introduction />
      </Caption>
      <Caption href='media' title={'media.title'}>
        <MediaSection />
      </Caption>
      <Caption href='social' title={'social.title'}>
        <SocialMedia />
      </Caption>
      <Footer />
    </div>
    {/* <div className='blur'></div> */}

  </>;
};

export default App