import React from 'react'

const Footer = () => {
    return (
        <div className='d-flex align-items-center justify-content-center text-center'>
            <p>2024, Naraa Pratap Reddy</p>
        </div>
    )
}

export default Footer