import React from 'react'
import { useTranslation } from 'react-i18next';

const YoutubeLink: React.FC = () => {
    // const width = window.innerWidth;
    return (
        <div>
            <p>
                <a
                    href="https://www.youtube.com/@NPRforMLC"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img alt='Youtube channel' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD+klEQVR4nO2Z6U8TQRTA+0fZgonxQ7cCKioeiIJROTRRwXh9UDRGMBI8g1Gjph+8FTQGEzEe4RQFwQMvTEFElOIHURPp7nbvZ96bHlRbkdotJdmXTHZmdvrm/eZ483Zqs1liiSWWpLQInGMR73S0+pwOnucckIzkw76c9hbBOWPBfxnv4xzVyTKajwXD2Q/EP/JTbDwfSvbsSQPgsplIsfHFCwbvA96VRmV9aBBA8gOfNSvRAM2TB+DsvokUqw31gCKuXgr8vNkAug5ad4cZMzAWB8DEiqWKnQQgVe0FcXMJ5eUzx9l7VxoISzOBd6UnBMJmBoCQ46JRV27WgnziCJuNdfkglhaCMfqVyvgUN6yh9trTTjDGfrLZa35A76cUgNa9pxe0t69AvXcbjB/faeS1nme0F2T3SQC/CNrzrtQFUK6eJ2P14U+gNt5lUCPDoA/0sXzfO9C9w6kL4N+xkQyhvXCwYvoB8FmzaAZQhLz5zGgvAvSHAUYCAN0dYIyNMYCm+ykCgEZ+HIgwJgLA0zsNAAZ/A/g8RFCU7/fQ/ggB+BiA1t6SugBqWxOApoF6px5AVclYMrqzndppTx4BGHrqAggFOaC97mHeqfc1O6k5B8g1B2kGlFs3QLlxJXUAkpFsFgAXWB45HMU76v0GOnHR21BU+n2UPAwmkGX4Q2Q59J7afvHSb1EH6kKdqNvUGcDYB12iWaKPDFMfpgFQbBNj48USfCdVlv8zhOw+aR5A0HdLlbtALCumOnxiOVEAanuzeQD6h/fhjlobmc9vbaSycu0CaI/bKI9PLI8H0N+9BaXuMhjfRkM6tK7HoFy/zM4Hw6A67MM0ANx8sQCYoWwm8BlcVjRLa5aBsNBJef+uLWypnK4BPmMmiJsK6Rn8nsA+TAPA2D4ugLJiOtTwQ0fInUv16HH8u7dR3l++FYRFHAMQRRMBdD0ugOAeoHpXWvR6LuAINC3FAcbPzLp82itiycokAcS5hGIBCCuyqS6YTF9C8W7iWABSFPdr6ib+mxsdbxBuzmgA/j3b6ZqF6uekh9qT2627ZL4bxUMmFgB6EXFtLkhHq8gtRiyVJZmgXHCDsCQDhBULwtcvRXn0xSYWLgdh1WKm96GJB5nsPhUTQDpWRRdZ8rmzIB8/FDkDhytBqt4HwuI5oNReZCM90AdiaRHd6InrC0B78zLRocSfV4sUzHlNDOa8n6NHpE77z8kDOO0t0UYCl0oonH7RTd+8FE6Pfg2H04FbigiR/OFwGttiON3vIR0UTp+uId3R+uQ5e9OkAfDPhejKpiC5HPNt8Qj+uTDVxvucjv22/xH8cwHv5//luj1xye7DZRP3yFtiiSWW2JIlvwChmkzbhV5IrAAAAABJRU5ErkJggg==" />
                </a>
            </p>
        </div>
    );
};

const Youtube = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column align-items-center m-2">
            <h4 className="py-3">{t('social.followyt')}</h4>
            <YoutubeLink />
        </div>
    )
}

export default Youtube