import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap'

export const ActionCall = () => {

    const { t } = useTranslation();

    const handlePhoneCall = () => {
        const phoneNumber = '636-291-6129'; // Replace with the phone number you want to call
        const telUrl = `tel:${phoneNumber}`;
        window.open(telUrl, '_self');
    };

    return (
        <div className='d-flex align-items-center justify-content-around'>
            <h5 className='text-primary me-2 font-22'>{t('cta.cta-text')}</h5>
            <Button
                className='d-flex align-items-center flex-shrink-0'
                color="primary"
                style={{ height: 50 }}
                onClick={handlePhoneCall}
            >
                <img className='me-2' alt='phone' width='25' height='25' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDklEQVR4nO3ZO2sUURiH8Z8x3iIW4iXgrbLwC0RTKAhaBFEQYiERtIqgnZ1fQBDR1lpQUwQvhaIIYiVqsNA0ARWDnaKi8YYRJCMDE1iWbNw5k5k9G/aBt5tinj1nznnf/9Khw6JnC4Yxipf4jnc4i2Uipwv7cQczSBrUE2wUIUtwFG/nefmkribQKyL6MZZDIKmpdMutabXASpzH30CJJKv76G6VRB9eFRRIaupSKyROYXoBJZLsYBioSqAHVxdYIKmpx1VI9Bb4oJMma6psie14XbJEgmdlSuzBlwokprCrLIm9+FGBxD1sLUviAH6XLPAVJ5XIIP6ULHGj7PbkYIDE+6zPutnEsx9wRMnsC9hO6dm/qa7vetrg2VGsL1tiN37mlEhXYEWDVv4EnuMXXuCQCtgRcMRea2WjNxfpUr/JKXE5+9WjoSeg7TgnQq4sBonTAdspOvpyzhMjsX0Ts+PpRA6JB1guQi7mkEjvgVUipD9HUPAR20SaO43lWI0hkTKU85S6JUK6MZlTJMkGq6gYDJCYTQGXiohHgSJpHRdRtD8Ta7KRh+ECEkk2o0TBaEGRcZEwXlDksEj4VkDiuoiYDJT4hA0i4kygSOmRTcit/jAgPIuStdkt3YzE59j+oKxnXTZf/E/kmDZgNW7PI3FXG9GFC3O0LWkyvlkbMpBltNPZwLWz1S/UoYNq+AdAr5fqdIQXUAAAAABJRU5ErkJggg=="></img>
                <h5 className='m-0'>{t('cta.cta-phonenumber')}</h5>
            </Button>
        </div>
    )
}


const CTA = () => {
    const { t } = useTranslation();

    return (
        <div className='py-2'>
            <Row className='mb-3'
                md="2"
                sm="2"
                xs="1">
                <Col className='p-md-5 p-lg-5'>
                    {/* Requirement on 12th May via whatsapp */}
                    {/* <h1 className="my-5">{t('cta.title')}</h1> */}
                </Col>
                <Col className='p-md-5 p-lg-5'>
                    <p className='my-5 pt-2 font-22'> {t('cta.tagline')} </p>
                    <ActionCall />
                </Col>
            </Row>
        </div>
    )
}

export default CTA