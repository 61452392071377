import React, {  } from 'react';
import "./introduction.css"


const AutoPlayVideoPlayer: React.FC = () => {

    return (
        <div className='video-container'>
            <iframe
                title='Naraa Pratap Reddy introduction'
                className='iframe-container'
                allow="fullscreen;autoplay"
                allowFullScreen
                height="100%"
                src="https://streamable.com/e/w47ad9?autoplay=1"
                width="100%"
            />
        </div>
    )
};

export default AutoPlayVideoPlayer;
