import { useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import Profile from './images/Profile.jpg';
import { useTranslation } from "react-i18next";

const AppNavBar = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar color="light" light expand="md" fixed='top'>
      <NavbarBrand>
        <div className='d-flex flex-row'>
          <NavLink href='#about' className='d-flex flex-row'>
          <img src={Profile} className='me-2' alt="Naara Prathap Reddy" width="30" height="30"></img>
          <h2>{t('nav.name')}</h2>
          </NavLink>
        
        </div>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="#intro">{t('nav.about')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#media">{t('nav.media')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#social">{t('nav.social')}</NavLink>
          </NavItem>
          <NavItem>
            {/* <LanguageSwitch /> */}
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppNavBar;
