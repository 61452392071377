import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
    title: string
    href: string
    children: React.ReactNode
}

const Caption: React.FC<IProps> = ({ title, href, children }) => {
    const { t } = useTranslation();
    return (
        <div id={href} className='caption-container border rounded-5 shadow'>
            <div className='caption-title'>
                <h2>{t(title)}</h2>
            </div>
            <div>{children}</div>
        </div>
    )
}

export default Caption