import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap'
import SocialCarousel, { CollagePhoto } from './SocialCarousel';
import img1 from './pictures/1.jpg'
import img2 from './pictures/2.jpg'
import img3 from './pictures/3.jpg'
import img4 from './pictures/4.jpg'
import img5 from './pictures/5.jpg' 
import img6 from './pictures/6.jpg'  
import img7 from './pictures/7.jpg' 
import img8 from './pictures/8.jpg' 
import img9 from './pictures/9.jpg' 
import img10 from './pictures/10.jpg' 
import img11 from './pictures/11.jpg' 
import img12 from './pictures/12.jpg' 
import img13 from './pictures/13.jpg' 
import img14 from './pictures/14.jpg' 
import img15 from './pictures/15.jpg' 

const items1: CollagePhoto[] = [
    {
      src: img1,
      altText: 'Slide 1',
      caption: 'Caption for Slide 1',
    },
    {
      src: img2,
      altText: 'Slide 2',
      caption: 'Caption for Slide 2',
    },
    {
      src: img3,
      altText: 'Slide 3',
      caption: 'Caption for Slide 3',
    },    
    {
      src: img4,
      altText: 'Slide 4',
      caption: 'Caption for Slide 4',
    },
    {
      src: img5,
      altText: 'Slide 5',
      caption: 'Caption for Slide 5',
    },
    {
      src: img6,
      altText: 'Slide 6',
      caption: '',
    },    
    {
      src: img7,
      altText: 'Slide 7',
      caption: '',
    },    
    {
      src: img8,
      altText: 'Slide 8',
      caption: '',
    }
  ];

const items2: CollagePhoto[] = [
    {
      src: img9,
      altText: 'Slide 9',
      caption: '',
    },
    {
      src: img10,
      altText: 'Slide 10',
      caption: '',
    },
    {
      src: img11,
      altText: 'Slide 11',
      caption: '',
    },
    {
      src: img12,
      altText: 'Slide 12',
      caption: '',
    },
    {
      src: img13,
      altText: 'Slide 13',
      caption: '',
    },
    {
      src: img14,
      altText: 'Slide 14',
      caption: '',
    },
    {
      src: img15,
      altText: 'Slide 15',
      caption: '',
    }
  ];


const MediaSection = () => {
    const { t } = useTranslation();

    return (
        <>
            <Row>
                <h3 className='pt-5 text-center'>{t('media.caption')}</h3>
                <p className='pb-5 text-center'>{t('media.subcaption')}</p>
            </Row>
            <Row
                md="2"
                sm="2"
                xs="1">
                <Col className='p-md-2'>
                    <SocialCarousel items={items1} /> 
                </Col >
                <Col className='p-md-2'>
                    <SocialCarousel items={items2}/>
                </Col>
            </Row>
        </>
    )
}

export default MediaSection