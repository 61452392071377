
const FacebookPlugin = () => {
    // const width = window.innerWidth;

    return (
        <div className="d-flex flex-column align-items-center m-2">
            <h4 className="py-3">Facebook updates</h4>
            <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNPRforMLC&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true"
                width={`${100}%`}
                height={"500"}
                style={{ border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                title="NPR for MLC"
            ></iframe>
        </div>
    );
};


export default FacebookPlugin